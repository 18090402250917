import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'next/router'
import Link from 'next/link'
import { getParentCategories, getParentCategory, getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { FilterButtons } from 'SRC/modules/layout/Header/components/FilterButtons'
import { getCurrentGroup, getGroups } from 'SRC/modules/groups/selectors'
import { SecureShoppingBox } from 'SRC/modules/common/components/SecureShoppingBox'

import { Item } from '../Item'
import { Sausages } from '../Sausages'
import { GroupCategoriesDropdown } from '../GroupCategoriesDropdown'

const getFilterBtnHref = router => {
  return {
    pathname: '/mobileAdFilter',
    query: router.query
  }
}

const getFilterBtnAs = router => {
  return `/mobilna-pretraga${router.asPath}`
}

const getMenuItems = ({
  parentCategories,
  currentParentCategory,
  currentSubCategory,
  currentGroup,
  groups,
  router
}) => {
  if (!Array.isArray(parentCategories)) return []

  if (!currentParentCategory || currentParentCategory.isSelfContainedParentCategory) {
    return parentCategories.map(parentCategory => {
      const as = `/${parentCategory.seo}`
      const href = { pathname: '/category', query: { parentCategory: parentCategory.seo } }

      return { ...parentCategory, href, as, isActive: router && decodeURI(router.asPath).startsWith(as) }
    })
  }

  const showGroupsCond = !currentGroup || currentGroup.isCategoryGroup || currentGroup.showCategoriesAsDropdown

  if (currentParentCategory?.isParentCategory && showGroupsCond && Array.isArray(groups)) {
    return groups.map(group => {
      const as = `/${currentParentCategory.seo}/${group.seo}`
      const href = { pathname: '/category', query: { parentCategory: currentParentCategory.seo, category: group.seo } }

      const isGroupActive = (router && decodeURI(router.asPath).startsWith(as)) ||
        (currentGroup && currentGroup.showCategoriesAsDropdown && currentGroup.seo === group.seo)

      return { ...group, href, as, isActive: isGroupActive }
    })
  }

  const showSubcategoriesCond = currentGroup && currentGroup.isGroup

  if (currentParentCategory?.isParentCategory && showSubcategoriesCond && Array.isArray(currentGroup.categories)) {
    return currentGroup.categories.map(category => {
      const as = `/${currentParentCategory.seo}/${category.seo}`
      const href = { pathname: '/category', query: { parentCategory: currentParentCategory.seo, category: category.seo } }

      return { ...category, href, as, isActive: router && decodeURI(router.asPath).startsWith(as) }
    })
  }

  return []
}

const Container = ({
  parentCategories,
  currentParentCategory,
  currentSubCategory,
  currentGroup,
  groups,
  router
}) => {
  const menuItems = getMenuItems({ parentCategories, currentParentCategory, currentSubCategory, currentGroup, groups, router })

  const showCategoryDropdown = currentGroup && currentGroup.showCategoriesAsDropdown && currentParentCategory &&
    !currentParentCategory.isSelfContainedParentCategory

  return (
    <React.Fragment>
      <FilterButtons />

      <section className='category-horizontal-menu-mobile'>
        <Sausages />

        {Array.isArray(menuItems) ? (
          <div className='category-horizontal-menu-mobile-items'>
            {menuItems.map(item => <Item key={item.id} {...item} />)}
          </div>
        ) : null}

        {showCategoryDropdown ? <GroupCategoriesDropdown /> : null}

        {router && currentSubCategory ? (
          <Link href={getFilterBtnHref(router)} as={getFilterBtnAs(router)}>
            <a className='filter-btn'>
              Filtriraj za detalje
            </a>
          </Link>
        ) : null}

        {/* <SecureShoppingBox /> */}
      </section>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    parentCategories: getParentCategories(state),
    currentParentCategory: getParentCategory(state),
    currentGroup: getCurrentGroup(state),
    currentSubCategory: getCurrentSubCategory(state),
    groups: getGroups(state)
  }
}

Container.propTypes = {
  parentCategories: PropTypes.array.isRequired,
  currentParentCategory: PropTypes.object.isRequired,
  currentGroup: PropTypes.object.isRequired,
  currentSubCategory: PropTypes.object.isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    seo: PropTypes.string,
    title: PropTypes.string
  })).isRequired,
}

Container.defaultProps = {
  parentCategories: []
}

Container.displayName = 'CategoryHorizontalMenuMobile'

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Container)
